<template>
  <div class="container">
    <div id="top"></div>
    <!--  轮播图区域  -->
    <div
      id="banner"
      :style="{
        backgroundImage: 'url(' + require('../assets/index_bg1.jpg') + ')',
        backgroundPosition: 0
      }"
    >
      <!--   轮播图左右图标   -->
      <div
        id="banner-left"
        :style="{
          backgroundImage: 'url(' + require('../assets/btn_03.png') + ')'
        }"
      ></div>
      <div
        id="banner-right"
        :style="{
          backgroundImage: 'url(' + require('../assets/btn_01.png') + ')'
        }"
      ></div>
      <div class="nav">
        <!--  logo区域    -->
        <Header />
      </div>
      <!--  首页简介区域    -->
      <div class="index_introduction1">
        <h1 style="">南京罕华</h1>
        <h2 style="">智慧能源管控综合解决方案服务商</h2>
        <h2 style="">推进全球工业人工智能进程</h2>
      </div>
      <div class="index_introduction2">
        <h1 style="">工业企业精准计量解决方案</h1>
        <p style="">
          提供流体精准计量解决方案、燃气锅炉能效诊断方案、<br />热工炉窑能效诊断方案等
        </p>
      </div>
      <div class="index_introduction3">
        <h1 style="">基于精准计量实现钢铁企业<br />煤气系统能源管控</h1>
        <p style="">
          运用于能源系统数据精准性及产耗平衡、能源设备在线节能降耗等
        </p>
      </div>
    </div>
    <!--  主体区域  -->
    <div class="main">
      <div class="content">
        <div class="index_service">
          <h2>服务项目</h2>
          <div class="service_content">
            <ul>
              <li>
                <div class="service_icon">
                  <img src="../assets/icon7.png" alt="" />
                </div>
                <p>WCC数据在线校准系统</p>
                <div class="service_more">
                  <router-link to="/product/product1" class="a">more</router-link>
                </div>
              </li>
              <li>
                <div class="service_icon">
                  <img src="../assets/icon1.png" alt="" />
                </div>
                <p>传感器设备</p>
                <div class="service_more">
                  <router-link to="/product/product1" class="a">more</router-link>
                </div>
              </li>
              <li>
                <div class="service_icon">
                  <img src="../assets/icon3.png" alt="" />
                </div>
                <p>EMS能源管理系统（冶金）</p>
                <div class="service_more">
                  <router-link :to="'/product/product1' + aboutId" class="a"
                    >more</router-link
                  >
                </div>
              </li>
              <li>
                <div class="service_icon">
                  <img src="../assets/icon6.png" alt="" />
                </div>
                <p>EMS能源管理系统（化工）</p>
                <div class="service_more">
                  <router-link to="/product/product1" class="a">more</router-link>
                </div>
              </li>
              <li>
                <div class="service_icon">
                  <img src="../assets/icon2.png" alt="" />
                </div>
                <p>CES碳排放管理系统</p>
                <div class="service_more">
                  <router-link to="/product/product1" class="a">more</router-link>
                </div>
              </li>
              <li>
                <div class="service_icon">
                  <img src="../assets/icon5.png" alt="" />
                </div>
                <p>重要能耗设备能效管理</p>
                <div class="service_more">
                  <router-link :to="'/product/product1' + aboutId" class="a"
                    >more</router-link
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--  招聘信息区域  -->
      <div class="join_us">
        <div class="join_us_main">
          <div class="join_us_content">
            <div class="join_us_content_header">
              <img src="../assets/join_us.png" alt="" />
              <span>Join us</span>
            </div>
            <ul>
              <li>
                <router-link class="aa" :to="'/join/' + joinId">
                  <div>
                    <img src="../assets/icon_society.png" alt="" />
                  </div>
                  <h3>社会招聘</h3>
                </router-link>
              </li>
              <li>
                <router-link class="aa" :to="'/join/' + joinId">
                  <div>
                    <img src="../assets/icon_master.png" alt="" />
                  </div>
                  <h3>校园招聘</h3>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="contact_us">
            <div class="contact_us_content_header">
              <img src="../assets/icon_phone.png" alt="" />
              <span>Contact us</span>
            </div>
            <div class="contact_us_content">
              <ul>
                <li>
                  <img src="../assets/icon_phone2.png" alt="" />
                  <span>电话: 025-52216015</span>
                </li>
                <li>
                  <img src="../assets/icon_address.png" alt="" />
                  <span>地址: 南京市鼓楼区集慧路18号联创大厦B座15层</span>
                </li>
                <li>
                  <img src="../assets/icon_email.png" alt="" />
                  <span>邮箱: hhfluid@163.com</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="partener">
        <div>
          <h1 class="partener_h1">合作伙伴及客户</h1>
        </div>
        <div class="scrollBox">
          <ul>
            <li><img src="../assets/client1.png" alt="" /></li>
            <li><img src="../assets/client2.png" alt="" /></li>
            <li><img src="../assets/client3.png" alt="" /></li>
            <li><img src="../assets/client4.png" alt="" /></li>
            <li><img src="../assets/client5.png" alt="" /></li>
            <li><img src="../assets/client1.png" alt="" /></li>
            <li><img src="../assets/client2.png" alt="" /></li>
            <!-- <li><img src="../assets/client3.png" alt="" /></li> -->
            <li><img src="../assets/client4.png" alt="" /></li>
            <li><img src="../assets/client5.png" alt="" /></li>
            <li><img src="../assets/client11.png" alt="" /></li>
            <li><img src="../assets/client13.png" alt="" /></li>
            <li><img src="../assets/client15.png" alt="" /></li>
            <br />
            <li><img src="../assets/client6.png" alt="" /></li>
            <li><img src="../assets/client7.png" alt="" /></li>
            <li><img src="../assets/client8.png" alt="" /></li>
            <li><img src="../assets/client9.png" alt="" /></li>
            <li><img src="../assets/client10.png" alt="" /></li>
            <li><img src="../assets/client6.png" alt="" /></li>
            <li><img src="../assets/client7.png" alt="" /></li>
            <li><img src="../assets/client8.png" alt="" /></li>
            <li><img src="../assets/client9.png" alt="" /></li>
            <li><img src="../assets/client10.png" alt="" /></li>
            <li><img src="../assets/client12.png" alt="" /></li>
            <li><img src="../assets/client14.png" alt="" /></li>
          </ul>
        </div>
      </div>
    </div>
    <!--  底部区域  -->
    <div class="bottom">
      <dl>
        <dt>产品技术</dt>
        <!-- <dd><router-link :to="'/goods/' + goodsId">产品体系平台</router-link></dd> -->
        <dd>
          <router-link :to="'/product/product1'"
            >WCC数据在线校准系统</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >传感器设备</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >EMS能源管理系统（冶金）</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >EMS能源管理系统（化工）</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >CES碳排放管理系统</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >重要能耗设备能效管理</router-link
          >
        </dd>
      </dl>
      <dl>
        <dt>加入我们</dt>
        <dd><router-link to="/join">校园招聘</router-link></dd>
        <dd><router-link to="/join">社会招聘</router-link></dd>
      </dl>
      <dl>
        <dt>关于我们</dt>
        <dd>
          <router-link :to="'/about/' + introductionId">公司简介</router-link>
        </dd>
        <dd><router-link :to="'/about/' + cultureId">公司文化</router-link></dd>
        <dd>
          <router-link :to="'/about/' + relationId">联系我们</router-link>
        </dd>
        <dd><router-link :to="'/about/' + addressId">公司地址</router-link></dd>
      </dl>
      <dl>
        <dt>新闻中心</dt>
        <dd><router-link to="/news">公司新闻</router-link></dd>
      </dl>
      <dl class="wx">
        <dt>关注我们</dt>
        <dd>
          <img src="../assets/wx.jpg" title="南京罕华流体技术有限公司" />
        </dd>
      </dl>
      <div class="copyRight">
        <p>
          Copyright 2020 ©南京罕华流体技术有限公司 |
          <a href="http://www.beian.miit.gov.cn">苏ICP备19074445号</a>
        </p>
      </div>
    </div>
  </div>
</template>
<script src="../assets/static/js/jquery-3.5.1.min.js"></script>
<script>
import $ from "jquery";
import Header from "../components/head/Header.vue";
export default {
  components: {
    Header
  },
  data() {
    return {
      goodsId: 0,
      content1Id: 1,
      content2Id: 2,
      aboutId: 0,
      joinId: 0,
      newsId: 0,
      productId: 0,
      qualityId: 0,
      indexId: 0,
      B: "",
      bb1: "",
      bb2: "",
      num: 1,
      p1: 0,
      p2: 0,
      introductionId: 1,
      cultureId: 2,
      relationId: 3,
      addressId: 4
    };
  },
  mounted: function() {
    var _this = this;
    var oBanner = document.getElementById("banner");
    clearInterval(this.bb1);
    clearInterval(this.bb2);
    clearInterval(this.B);
    this.B = setInterval(function() {
      _this.lunbo();
    }, 8000);
    var id = this.$route.params.id;
    if (id === "0") {
      document.querySelector("#top").scrollIntoView(true);
    }
    document.addEventListener("scroll", function(event) {
      var scrollDistance =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollDistance >= 230) {
        // 触发的位置
        document.getElementsByClassName("nav")[0].style.cssText = "opacity: 1;";
      } else {
        document.getElementsByClassName("nav")[0].style.cssText = "opacity: 1;";
      }
    });
    $("#banner-left").click(function() {
      _this.handleLeftClick();
    });
    $("#banner-right").click(function() {
      _this.handleRightClick();
    });
    //鼠标滚动触发
    $(window).on("scroll", function() {
      if ($(window).scrollTop() >= 100) {
        $(".nav").css("opacity", 1);
      }
      if ($(window).scrollTop() >= 320) {
        //淡入
        $(".index_service").fadeIn(1000);
      }
      if ($(window).scrollTop() >= 900) {
        $(".join_us_content").animate(
          {
            right: 0,
            opacity: 1
          },
          1000
        );
        $(".contact_us").animate(
          {
            left: 0,
            opacity: 1
          },
          1000
        );
      }
      if ($(window).scrollTop() >= 1300) {
        $(".partener_h1").fadeIn(1000);
        $(".scrollBox").fadeIn(1000);
      }
    });
  },
  methods: {
    toTop() {
      document.querySelector("#top").scrollIntoView(true);
    },
    lunbo() {
      var oBanner = document.getElementById("banner");
      var num = this.num;
      num = num + 1;
      if (num > 3) {
        num = 1;
      }
      this.num = num;
      if (num === 2) {
        // $('.index_introduction2').css('display', 'block')
        $(".index_introduction1").css("display", "none");
        $(".index_introduction3").css("display", "none");
        $(".index_introduction2").fadeIn(2000);
        oBanner.style.backgroundImage =
          "url(" + require("../assets/index_bg" + num + ".jpg") + ")";
      } else if (num === 3) {
        // $('.index_introduction3').css('display', 'block')
        $(".index_introduction1").css("display", "none");
        $(".index_introduction2").css("display", "none");
        $(".index_introduction3").fadeIn(2000);
        oBanner.style.backgroundImage =
          "url(" + require("../assets/index_bg" + num + ".jpg") + ")";
      } else if (num === 1) {
        // $('.index_introduction1').css('display', 'block')
        $(".index_introduction2").css("display", "none");
        $(".index_introduction3").css("display", "none");
        $(".index_introduction1").fadeIn(2000);
        oBanner.style.backgroundImage =
          "url(" + require("../assets/index_bg" + num + ".jpg") + ")";
      }
    },
    handleLeftClick() {
      var _this = this;
      var oBanner = document.getElementById("banner");
      clearInterval(this.B); // 点击时清除自动轮播
      clearInterval(this.bb1); // 点击时清除自动轮播
      clearInterval(this.bb2); // 点击时清除自动轮播
      this.p1--;
      --this.num;
      //  限制轮播极限，以免越界
      if (this.num > 3) {
        this.num = 1;
      }
      if (this.num < 1) {
        this.num = 3;
      }
      if (this.num === 2) {
        $(".index_introduction1").css("display", "none");
        $(".index_introduction3").css("display", "none");
        $(".index_introduction2").css("display", "block");
        oBanner.style.backgroundImage =
          "url(" + require("../assets/index_bg" + this.num + ".jpg") + ")";
      } else if (this.num === 3) {
        $(".index_introduction1").css("display", "none");
        $(".index_introduction2").css("display", "none");
        $(".index_introduction3").css("display", "block");
        oBanner.style.backgroundImage =
          "url(" + require("../assets/index_bg" + this.num + ".jpg") + ")";
      } else if (this.num === 1) {
        $(".index_introduction2").css("display", "none");
        $(".index_introduction3").css("display", "none");
        $(".index_introduction1").css("display", "block");
        oBanner.style.backgroundImage =
          "url(" + require("../assets/index_bg" + this.num + ".jpg") + ")";
      }
      // oBanner.style.backgroundPosition = this.p1 * 1920 + 'px' //  每次移动980 ，背景重复情况下制造推动的视觉效果
      this.bb1 = setInterval(function() {
        _this.lunbo();
      }, 8000);
    },
    handleRightClick() {
      var _this = this;
      var oBanner = document.getElementById("banner");
      clearInterval(this.B); // 点击时清除自动轮播
      clearInterval(this.bb2); // 点击时清除自动轮播
      clearInterval(this.bb1); // 点击时清除自动轮播
      ++this.num;
      this.p++;
      //  限制轮播极限，以免越界
      if (this.num > 3) {
        this.num = 1;
      }
      if (this.num < 1) {
        this.num = 3;
      }
      if (this.num === 2) {
        $(".index_introduction1").css("display", "none");
        $(".index_introduction3").css("display", "none");
        $(".index_introduction2").css("display", "block");
        oBanner.style.backgroundImage =
          "url(" + require("../assets/index_bg" + this.num + ".jpg") + ")";
      } else if (this.num === 3) {
        $(".index_introduction1").css("display", "none");
        $(".index_introduction2").css("display", "none");
        $(".index_introduction3").css("display", "block");
        oBanner.style.backgroundImage =
          "url(" + require("../assets/index_bg" + this.num + ".jpg") + ")";
      } else if (this.num === 1) {
        $(".index_introduction2").css("display", "none");
        $(".index_introduction3").css("display", "none");
        $(".index_introduction1").css("display", "block");
        oBanner.style.backgroundImage =
          "url(" + require("../assets/index_bg" + this.num + ".jpg") + ")";
      }
      // oBanner.style.backgroundPosition = this.p2 * 1920 + 'px' //  每次移动980 ，背景重复情况下制造推动的视觉效果
      this.bb2 = setInterval(function() {
        _this.lunbo();
      }, 8000);
    }
  },
  beforeDestroy() {
    clearInterval(this.B);
  }
};
</script>

<style lang="less" scoped>
@keyframes card {
  0% {
    transform: translateY(120px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
/* 清除浮动 */
.clearfix:after {
  visibility: hidden;
  clear: both;
  display: block;
  content: ".";
  height: 0;
}

.clearfix {
  *zoom: 1;
}
.container {
  animation: card 1s linear;
  width: 100%;
}
#banner {
  position: relative;
  width: 100%;
  height: 1000px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: background-image 2s linear;
  .index_introduction1 {
    display: block;
    color: white;
    position: absolute;
    top: 300px;
    left: 200px;
    h1 {
      font-size: 60px;
      margin-bottom: 20px;
      letter-spacing: 5px;
    }
    h2 {
      font-size: 30px;
      margin-bottom: 10px;
      letter-spacing: 5px;
      text-align: left;
    }
  }
  .index_introduction2 {
    display: none;
    color: white;
    position: absolute;
    top: 350px;
    left: 150px;
    h1 {
      font-size: 50px;
      margin-bottom: 20px;
    }
    p {
      font-size: 30px;
      color: #ffffff;
    }
  }
  .index_introduction3 {
    display: none;
    color: white;
    position: absolute;
    top: 150px;
    right: 50px;
    text-align: center;
    h1 {
      font-size: 50px;
      margin-bottom: 20px;
    }
    p {
      font-size: 30px;
      color: #ddd;
    }
  }
}
#banner-left {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 0;
  opacity: 0;
  transition: all 1s linear;
  cursor: pointer;
  background-size: 100% 100%;
}
#banner-right {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  right: 0;
  opacity: 0;
  transition: all 1s linear;
  cursor: pointer;
  background-size: 100% 100%;
}
#banner:hover > #banner-right,
#banner:hover > #banner-left {
  opacity: 0.4;
}

//  主题区域
.main {
  width: 100%;
  //background-color: #eee;
  background: url("../assets/main_bg2.jpg");
  background-size: 100% 100%;
  .content {
    width: 1400px;
    height: 1000px;
    margin: 0px auto;
    //border: 1px solid red;
    .index_service {
      width: 100%;
      display: none;
      padding-top: 200px;
      h2 {
        text-align: center;
        font-size: 30px;
        margin: 0px auto;
        margin-bottom: 70px;
        color: white;
        transform: translateY(-50px);
        //animation: tt 2s linear;
      }
      .service_content {
        width: 100%;
        transform: translateY(-50px);
        //animation: tt 2s linear;
        ul li {
          float: left;
          width: 450px;
          height: 300px;
          margin-right: 24px;
          background-color: rgba(255, 255, 255, 0.8);
          transform: translateY(0);
          transition: all 0.4s linear;
          .service_icon {
            margin-top: 30px;
            text-align: center;
            img {
              width: 70px;
              height: 70px;
            }
          }
          p {
            text-align: center;
            font-size: 22px;
            margin-top: 30px;
            font-weight: bold;
            height: 60px;
          }
          .service_more {
            width: 100%;
            margin-top: 20px;
            .a {
              display: block;
              margin: 0 auto;
              text-align: center;
              width: 130px;
              height: 50px;
              border-radius: 30px;
              line-height: 45px;
              border: 2px solid blue;
              color: black;
              font-weight: bold;
              font-size: 20px;
            }
          }
        }
        li:nth-child(3n) {
          margin-right: 0px;
        }
        li:nth-child(-n + 3) {
          margin-bottom: 25px;
        }
        li:hover {
          transform: translateY(-10px);
          background-color: white;
          transition: all 0.4s linear;
        }
        li:hover .service_more .a {
          background-color: #2466de;
          color: white;
        }
      }
      @keyframes tt {
        0% {
          transform: translateY(0px);
          opacity: 0;
        }
        100% {
          transform: translateY(-50px);
          opacity: 1;
        }
      }
    }
  }
  .join_us {
    width: 100%;
    height: 1000px;
    background: url("../assets/join_us_bg1.jpg");
    background-size: 100% 100%;
    .join_us_main {
      position: relative;
      width: 100%;
      //padding-top: 100px;
      .join_us_content {
        position: absolute;
        top: 2px;
        right: 1000px;
        //display: none;
        width: 950px;
        opacity: 0;
        text-align: center;
        background-color: rgba(2, 172, 67, 0.4);
        padding-top: 40px;
        .join_us_content_header {
          img {
            width: 60px;
            height: 60px;
            vertical-align: -10px;
            transition: all 0.6s linear;
          }
          span {
            color: white;
            font-size: 50px;
            font-weight: bold;
            text-align: center;
          }
        }
        .join_us_content_header:hover img {
          transform: rotateY(180deg);
          cursor: pointer;
        }
        ul li {
          display: inline-block;
          width: 200px;
          height: 200px;
          color: white;
          margin: 100px 100px;
          border: 1px solid #ffffff;
          .aa {
            display: inline-block;
            width: 100%;
            height: 100%;
            color: white;
            img {
              width: 100px;
              height: 100px;
              margin-top: 20px;
              transition: all 0.6s linear;
            }
            h3 {
              margin-top: 20px;
              font-size: 24px;
            }
          }
        }
        li:hover .aa img {
          transform: rotateY(180deg);
        }
      }
      .join_us_content:hover {
        background-color: rgba(2, 172, 67, 0.9);
      }
      .contact_us {
        position: absolute;
        top: 507px;
        right: 0;
        width: 950px;
        opacity: 0;
        background-color: rgba(232, 154, 18, 0.6);
        padding-top: 50px;
        .contact_us_content_header {
          text-align: center;
          img {
            width: 50px;
            height: 50px;
            margin-right: 8px;
            vertical-align: -10px;
            transition: all 0.6s linear;
          }
          span {
            color: white;
            font-size: 50px;
            font-weight: bold;
            text-align: center;
          }
        }
        .contact_us_content_header:hover img {
          transform: rotateY(180deg);
          cursor: pointer;
        }
        .contact_us_content {
          margin: 130px 0 130px 300px;
          ul li {
            margin: 20px 0;
            img {
              width: 30px;
              height: 30px;
              margin-right: 10px;
              vertical-align: bottom;
            }
            span {
              font-size: 24px;
            }
          }
        }
      }
      .contact_us:hover {
        background-color: rgba(232, 154, 18, 0.9);
      }
    }
  }
  .partener {
    position: relative;
    width: 100%;
    height: 900px;
    background: url("../assets/main_bg4.jpg") no-repeat;
    background-size: 100% 900px;
    //animation: tt 2s linear;
    .partener_h1 {
      color: white;
      position: absolute;
      top: 30%;
      left: 44%;
      font-size: 40px;
    }
    .scrollBox {
      display: none;
      position: absolute;
      margin-top: 20px;
      top: 40%;
      left: 23%;
      width: 1050px;
      height: 312px;
      overflow: hidden;
      //border: 1px solid red;
      ul {
        animation: myscroll 10s linear infinite;
        white-space: nowrap;
        backface-visibility: hidden;
        li {
          display: inline-block;
          width: 200px;
          height: 150px;
          margin-right: 10px;
          list-style: none;
          border: 1px solid black;
          background-color: rgba(255, 255, 255, 1);
          //text-align: center;
          //line-height: 100px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        li:nth-child(-n + 5) {
          margin-bottom: 10px;
        }
      }
      ul:hover {
        animation-play-state: paused;
      }
      @keyframes myscroll {
        0% {
          margin-left: 0px;
        }
        100% {
          margin-left: -1500px;
        }
      }
    }
  }
}

//  底部区域
.bottom {
  position: relative;
  width: 100%;
  height: 600px;
  background-color: black;
  color: white;
  padding-left: 200px;
  dl {
    float: left;
    height: 100px;
    margin: 130px 70px;
    dt {
      font-size: 28px;
      color: #fff;
      margin-bottom: 15px;
    }
    dd {
      font-size: 21px;
      margin: 8px 0;
      a {
        color: #ccc;
      }
    }
  }
  dl:first-child {
    margin-right: 30px;
  }
  .wx {
    dt {
      text-align: center;
    }
    dd img {
      width: 110px;
      height: 110px;
    }
  }
  .copyRight {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 22px;
    p {
      text-align: center;
      a {
        color: white;
      }
    }
  }
}
</style>
