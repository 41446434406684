<template>
  <div class="container">
    <div id="top"></div>
    <Header />
    <!--  头部区域  -->
    <div class="about_header">
      <h1 class="about_header_title">关于我们</h1>
    </div>
    <div id="introduction"></div>
    <!--  公司介绍  -->
    <div class="introduction">
      <h2>公司简介</h2>
      <div class="introduction_con_bg"></div>
      <div class="introduction_con">
        <h3>南京罕华———精准计量解决方案服务商</h3>
        <p>
          南京罕华流体技术有限公司成立于2017年，为江苏省、南京市、鼓楼区三方重点培育的<br>
          国家高新技术企业、国家双软认证企业、国家工业互联网产业联盟单位及江苏省双创人才企<br>
          业，由多名博士研究生联合创立，主要公司主营智慧能源管控及节能降碳业务，依托WCC数<br>
          据在线校准系统、智慧能碳系统平台(EMS智慧能源管控系统、CES碳平台及能效管理等)，服<br>
          务国家工业数字化转型及双碳落地<br>
        </p>
        <p>
          公司在中国宝武落地实施了国内首套自主知识产权的煤气自适应产消平衡系统、在山东<br>
          钢铁落地我国首套能源精准计量系统，同时，公司与中国铝业、中国质量认证中心、浙江金<br>
          控、新奥集团及川锅集团等行业龙头建立了战略合作伙伴关系，致力于打造数字化转型及碳<br>
          交易的数据底座，为整个行业的发展作出积极卓越的贡献。用技术促进“智慧能源及双碳”目<br>
          标的实现，为整个行业的发展作出积极、卓越的贡献。
        </p>
      </div>
    </div>
    <div id="culture"></div>
    <!--  公司文化  -->
    <div class="culture">
      <h2>公司文化</h2>
      <div class="culture_con">
        <h4> 公司的文化理念是：<span>Don't  lie！</span> </h4>
        <h5>真诚、真实对待客户，<span>Don't  lie！</span></h5>
        <h5>真诚、真实对待同事，<span>Don't  lie！</span></h5>
        <h5>真诚、真实对待朋友，<span>Don't  lie！</span></h5>
        <h5>真诚、真实对待家人，<span>Don't  lie！</span></h5>
        <div class="culture_con_img">
          <img src="../assets/about_culture_bg3.jpg" alt="">
        </div>
      </div>
      <div class="culture_hope">
        <div class="culture_hope_details">
          <ul>
            <li>
              <h2>企业愿景</h2>
              <p>做一家全球领先的工业人工智能系统公司！</p>
            </li>
            <li>
              <h2>企业使命</h2>
              <p>推进全球工业人工智能进程！</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div id="relation"></div>
    <!--  联系我们  -->
    <div class="relation">
      <h2>联系我们</h2>
      <ul>
        <li>
          <img src="../assets/icon_phone1.png" alt="">
          <span>电话</span>
          <p>025-52216015</p>
        </li>
        <li>
          <img src="../assets/icon_wechat.png" alt="">
          <span>公众号</span>
          <p>罕华招聘</p>
        </li>
        <!-- <li>
          <img src="../assets/icon_qq.png" alt="">
          <span>QQ</span>
          <p>888888888</p>
        </li> -->
        <li>
          <img src="../assets/icon_email1.png" alt="">
          <span>邮箱</span>
          <p>hhfluid@163.com</p>
        </li>
      </ul>
    </div>
    <div id="address"></div>
    <!--  公司地址  -->
    <div class="address">
      <div class="address_bg"></div>
      <div class="address_con">
        <h2>公司地址</h2>
        <el-amap class="amap-box" :vid="'amap-vue'" :center="center" :zoom="zoom" :plugin="plugin">
          <el-amap-marker vid="marker" :position="center" :label="label" :title="title"></el-amap-marker>
        </el-amap>
        <p>地址：南京市鼓楼区集慧路18号联创大厦B座15层</p>
      </div>
    </div>
    <!--  底部区域  -->
    <div class="bottom">
      <dl>
        <dt>产品技术</dt>
        <!-- <dd><router-link :to="'/goods/' + goodsId">产品体系平台</router-link></dd> -->
        <dd>
          <router-link :to="'/product/product1'"
            >WCC数据在线校准系统</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >传感器设备</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >EMS能源管理系统（冶金）</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >EMS能源管理系统（化工）</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >CES碳排放管理系统</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >重要能耗设备能效管理</router-link
          >
        </dd>
      </dl>
      <dl>
        <dt>加入我们</dt>
        <dd><router-link to="/join">校园招聘</router-link></dd>
        <dd><router-link to="/join">社会招聘</router-link></dd>
      </dl>
      <dl>
        <dt>关于我们</dt>
        <dd>
          <router-link :to="'/about/' + introductionId">公司简介</router-link>
        </dd>
        <dd><router-link :to="'/about/' + cultureId">公司文化</router-link></dd>
        <dd>
          <router-link :to="'/about/' + relationId">联系我们</router-link>
        </dd>
        <dd><router-link :to="'/about/' + addressId">公司地址</router-link></dd>
      </dl>
      <dl>
        <dt>新闻中心</dt>
        <dd><router-link to="/news">公司新闻</router-link></dd>
      </dl>
      <dl class="wx">
        <dt>关注我们</dt>
        <dd>
          <img src="../assets/wx.jpg" title="南京罕华流体技术有限公司" />
        </dd>
      </dl>
      <div class="copyRight">
        <p>
          Copyright 2020 ©南京罕华流体技术有限公司 |
          <a href="http://www.beian.miit.gov.cn">苏ICP备19074445号</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Header from '../components/head/Header.vue'
export default {
  components: {
    Header
  },
  data () {
    return {
      goodsId: 0,
      content1Id: 1,
      content2Id: 2,
      aboutId: 0,
      joinId: 0,
      newsId: 0,
      productId: 0,
      qualityId: 0,
      indexId: 0,
      introductionId: 1,
      cultureId: 2,
      relationId: 3,
      addressId: 4,
      center: [118.723250, 32.034222],
      zoom: 19,
      label: {
        content: '',
        offset: [-10, -34]
      },
      title: '南京罕华流体技术有限公司',
      plugin: ['ToolBar', 'Scale']
    }
  },
  mounted () {
    $('.about_header_title').fadeIn(1000)
    $('.introduction').fadeIn(3000)
    $('.culture').fadeIn(3000)
    $('.relation').fadeIn(3000)
    $('.address').fadeIn(3000)
    $('.bottom').fadeIn(3000)
    this.move()
    $(window).on('scroll', function () {
      if ($(window).scrollTop() >= 400) {
        $('.nav').css('background-color', 'rgba(169,169,171,0.3)')
      } else {
        $('.nav').css('background-color', 'rgba(255,255,255,.1)')
      }
      if ($(window).scrollTop() >= 50) {
        $('.culture').fadeIn(3000)
      }
      if ($(window).scrollTop() >= 400) {
        $('.relation').fadeIn(3000)
      }
      if ($(window).scrollTop() >= 600) {
        $('.address').fadeIn(3000)
      }
      if ($(window).scrollTop() >= 700) {
        $('.bottom').fadeIn(3000)
      }
    })
  },
  methods: {
    move () {
      var id = this.$route.params.id
      if (id === '1') {
        document.querySelector('#introduction').scrollIntoView(true)
      } else if (id === '2') {
        document.querySelector('#culture').scrollIntoView(true)
      } else if (id === '3') {
        document.querySelector('#relation').scrollIntoView(true)
      } else if (id === '4') {
        document.querySelector('#address').scrollIntoView(true)
      } else if (id === '0') {
        document.querySelector('#top').scrollIntoView(true)
      }
    }
  }
}

</script>

<style lang="less" scoped>
@keyframes card  {
  0% {
    transform: translateY(120px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.container {
  width: 100%;
  position: relative;
  animation: card 1s linear;
}
.about_header {
  letter-spacing: 10px;
  width: 100%;
  height: 600px;
  background: url("../assets/about_bg2.jpg") no-repeat;
  background-size: 100% 100%;
  padding-top: 300px;
  .about_header_title {
    display: none;
    width: 100%;
    color: white;
    margin: 0 auto;
    font-size: 50px;
    text-align: center;
  }
}

.introduction {
  display: none;
  position: relative;
  width: 100%;
  height: 500px;
  h2 {
    font-size: 35px;
    text-align: center;
    margin: 60px 0;
    font-weight: lighter;
  }
  .introduction_con {
    position: absolute;
    top: 90px;
    left: 530px;
    padding-top: 80px;
    padding-bottom: 60px;
    h3 {
      font-size: 30px;
      color: white;
      text-indent: 120px;
    }
    p {
      text-indent: 35px;
      margin-top: 30px;
      color: white;
      font-size: 20px;
      line-height: 150%;
      font-weight: lighter;
    }
  }
  .introduction_con_bg {
    position: relative;
    width: 100%;
    height: 500px;
    background: url("../assets/about_bg3.jpg");
    background-size: 100% 100%;
  }
  .introduction_con_bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.culture {
  display: none;
  width: 100%;
  h2 {
    font-size: 35px;
    text-align: center;
    margin: 60px 0;
    font-weight: lighter;
  }
  .culture_con {
    position: relative;
    width: 100%;
    height: 600px;
    background-color: rgba(247,247,247,0.5);
    padding-left: 120px;
    padding-top: 80px;
    h4 {
      font-size: 30px;
      margin-bottom: 80px;
      font-weight: lighter;
      span {
        color: #0099ff;
        font-weight: bold;
        font-size: 36px;
      }
    }
    h5 {
     font-size: 22px;
      margin: 40px 0;
      font-weight: lighter;
      span {
        font-weight: bold;
        font-size: 27px;
      }
    }
    .culture_con_img {
      position: absolute;
      top: 0;
      right: 0;
      width: 1000px;
      height: 600px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .culture_hope {
    width: 100%;
    margin: 150px 0;
    .culture_hope_details {
      width: 1600px;
      height: 350px;
      margin: 0 auto;
      ul li {
        display: inline-block;
        width: 700px;
        height: 350px;
        margin: 0 49px;
        padding-top: 30px;
        border-radius: 20px;
        transition: all .2s linear;
        h2 {
          font-size: 35px;
          margin-bottom: 80px;
        }
        p {
          font-size: 25px;
          text-align: center;
        }
      }
      li:first-child {
        color: white;
        background: url("../assets/about_culture_bg2.jpg");
        background-size: 100% 100%;
      }
      li:last-child {
        color: #1F2A63;
        background: url("../assets/about_bg.jpg");
        background-size: 100% 100%;
      }
      li:hover {
        transform: scale(1.1);
      }
    }
  }
}

.relation {
  display: none;
  width: 1600px;
  margin: 0 auto;
  text-align: center;
  h2 {
    font-size: 35px;
    margin-bottom: 50px;
  }
  ul li {
    display: inline-block;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    margin: 50px 40px;
    padding-top: 90px;
    background-color: rgba(240,240,240,.6);
    transition: all 1s linear;
    img {
      width: 40px;
      height: 40px;
      vertical-align: bottom;
    }
    span {
      font-size: 29px;
      margin-left: 10px;
    }
    p {
      font-size: 25px;
      margin: 40px 0;
    }
  }
  li:first-child:hover {
    transform: rotateY(360deg);
    background-color: rgb(6, 185, 182,.3);
  }
  li:nth-child(2):hover {
    transform: rotateY(360deg);
    background-color: rgb(185, 113, 6,.3);
  }
  li:nth-child(3):hover {
    transform: rotateY(360deg);
    background-color: rgb(233, 212, 60,.3);
  }
  li:last-child:hover {
    transform: rotateY(360deg);
    background-color: rgb(30, 185, 6,.3);
  }
}

.address {
  display: none;
  width: 100%;
  position: relative;
}
.address_bg {
  position: relative;
  width: 100%;
  height: 800px;
  background: url("../assets/about_bg5.jpg");
  background-size: 100% 100%;
}
.address_bg::before {
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.2);
}

.address_con {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 800px;
  text-align: center;
  h2 {
    position: absolute;
    top: 200px;
    left: 600px;
    font-size: 35px;
    color: white;
  }
  .amap-box {
    position: absolute;
    top: 140px;
    left: 1100px;
    width: 700px;
    height: 500px;
  }
  //img {
  //  width: 110px;
  //  height: 60px;
  //  margin-bottom: 30px;
  //}
  p {
    position: absolute;
    bottom: 350px;
    left: 450px;
    font-size: 26px;
    color: white;
  }
}

//  底部区域
.bottom {
  position: relative;
  width: 100%;
  height: 600px;
  background-color: #1F2A63;
  color: white;
  padding-left: 200px;
  dl {
    float: left;
    height: 100px;
    margin: 130px 70px;
    dt {
      font-size: 28px;
      color: #fff;
      margin-bottom: 15px;
    }
    dd {
      font-size: 21px;
      margin: 8px 0;
      a {
        color: #ccc;
      }
    }
  }
  dl:first-child {
    margin-right: 30px;
  }
  .wx {
    dt {
      text-align: center;
    }
    dd img {
      width: 110px;
      height: 110px;
    }
  }
  .copyRight {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 22px;
    p {
      text-align: center;
      a {
        color: white;
      }
    }
  }
}
</style>
