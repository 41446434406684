<template>
   <div class="nav" :class="{scollBg:flag}">
      <!--  logo区域    -->
      <div class="nav_logo">
        <router-link to="/index" class="logo">
          <img src="../../assets/logo.png" alt="">
          <div class="logo-text">
              <span>南京罕华流体技术有限公司</span>
          </div>
        </router-link>
      </div>
      <ul class="nav_item">
        <li>
          <el-dropdown>
            <span class="el-dropdown-link">
              <router-link class="nav_menu" :to="'/quality/' + qualityId">荣誉资质</router-link><i class="el-icon-arrow-down el-icon--right nav_menu_icon"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="nav_submenu">
              <el-dropdown-item><router-link :to="'/quality/' + content1Id" class="nav_submenu_a" style="color: #1F2A63;width: 150px;height: 50px;display: block;text-align: center;line-height: 50px;font-size: 16px">AAA资信</router-link></el-dropdown-item>
              <el-dropdown-item><router-link :to="'/quality/' + content2Id" class="nav_submenu_a" style="color: #1F2A63;width: 150px;height: 50px;display: block;text-align: center;line-height: 50px;font-size: 16px">ISO体系认证</router-link></el-dropdown-item>
              <el-dropdown-item disabled><router-link to="/quality" class="nav_submenu_a" style="color: #1F2A63;width: 150px;height: 50px;display: block;text-align: center;line-height: 50px;font-size: 16px">知识产权贯标</router-link></el-dropdown-item>
              <el-dropdown-item disabled><router-link to="/quality" class="nav_submenu_a" style="color: #1F2A63;width: 150px;height: 50px;display: block;text-align: center;line-height: 50px;font-size: 16px">高新技术企业</router-link></el-dropdown-item>
              <el-dropdown-item disabled><router-link to="/quality" class="nav_submenu_a" style="color: #1F2A63;width: 150px;height: 50px;display: block;text-align: center;line-height: 50px;font-size: 16px">创业人才支持</router-link></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown>
            <span class="el-dropdown-link">
              <router-link class="nav_menu" :to="'/product/product1'">产品解决方案</router-link>
            </span>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown>
            <span class="el-dropdown-link">
              <router-link class="nav_menu" :to="'/news/' + newsId">新闻中心</router-link>
            </span>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown>
            <span class="el-dropdown-link">
              <router-link class="nav_menu" :to="'/join'">加入我们</router-link>
            </span>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown>
            <span class="el-dropdown-link">
              <router-link class="nav_menu" :to="'/about/'+ aboutId"><span  @click="toTop">关于我们</span></router-link><i class="el-icon-arrow-down el-icon&#45;&#45;right nav_menu_icon"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="nav_submenu">
              <el-dropdown-item><router-link :to="'/about/'+ introductionId" class="nav_submenu_a" style="color: #1F2A63;width: 150px;height: 50px;display: block;text-align: center;line-height: 50px;font-size: 16px"><div @click="toIntroduction">公司简介</div></router-link></el-dropdown-item>
              <el-dropdown-item><router-link :to="'/about/'+ cultureId" class="nav_submenu_a" style="color: #1F2A63;width: 150px;height: 50px;display: block;text-align: center;line-height: 50px;font-size: 16px"><div  @click="toCulture">公司文化</div></router-link></el-dropdown-item>
              <el-dropdown-item><router-link :to="'/about/'+ relationId" class="nav_submenu_a" style="color: #1F2A63;width: 150px;height: 50px;display: block;text-align: center;line-height: 50px;font-size: 16px"><div  @click="toRelation">联系我们</div></router-link></el-dropdown-item>
              <el-dropdown-item><router-link :to="'/about/'+ addressId" class="nav_submenu_a" style="color: #1F2A63;width: 150px;height: 50px;display: block;text-align: center;line-height: 50px;font-size: 16px"><div  @click="toAddress">公司地址</div></router-link></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <el-dropdown>
            <span class="el-dropdown-link">
              <router-link :to="'/index'" class="nav_menu">首页</router-link>
            </span>
          </el-dropdown>
        </li>
      </ul>
    </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {
      goodsId: 0,
      content1Id: 1,
      content2Id: 2,
      aboutId: 0,
      joinId: 0,
      newsId: 0,
      productId: 0,
      qualityId: 0,
      indexId: 0,
      introductionId: 1,
      cultureId: 2,
      relationId: 3,
      addressId: 4,
      center: [118.723250, 32.034222],
      zoom: 19,
      label: {
        content: '',
        offset: [-10, -34]
      },
      title: '南京罕华流体技术有限公司',
      plugin: ['ToolBar', 'Scale'],
      flag: false
    }
  },
  components: {

  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      if (window.scrollY > 90) {
        this.flag = true
      } else {
        this.flag = false
      }
    },
    toTop () {
      document.querySelector('#top').scrollIntoView(true)
    },
    toIntroduction () {
      document.querySelector('#introduction').scrollIntoView(true)
    },
    toCulture () {
      document.querySelector('#culture').scrollIntoView(true)
    },
    toRelation () {
      document.querySelector('#relation').scrollIntoView(true)
    },
    toAddress () {
      document.querySelector('#address').scrollIntoView(true)
    }
  },
  beforeDestroy () {
    // 移除滚动事件监听
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style scoped lang="less">
.nav {
  width: 100%;
  position: fixed;
  height: 90px;
  background: radial-gradient(914.73% 50% at 50% 50.5%, rgba(55, 208, 153, 0.20) 0%, rgba(50, 157, 255, 0.30) 100%);
  z-index: 2;
}
.scollBg{
  background: linear-gradient(90deg, #C7DDFB 0.02%, #CEF6FF 50.99%,  #C2E8E8 99.98%);
  color: #6D6E86 !important;
  .logo-text {
    background: linear-gradient(180deg, #66cc94 27.19%, #0D6AAA 75.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .nav_menu {
    color: #1F2A63 !important;
  }
  .nav_menu_icon {
    color: #1F2A63!important;
  }
}
.nav:hover {
  transition: all 0.3s linear;
  background-color: white!important;
  .logo-text {
    background: linear-gradient(180deg, #66cc94 27.19%, #0D6AAA 75.44%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .nav_menu {
    color: #1F2A63;
  }
  .nav_submenu {
  color: #1F2A63;
  }
  .nav_menu_icon {
    color: #1F2A63;
  }
}
.nav_logo {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 100px;
  margin-right: 510px;
}
.nav_logo .logo {
  display: inline-block;
  position: relative;
  height: 90px;
  // line-height: 90px;
  display: flex;
  align-items: center;
}
.nav_logo .logo img {
  width: 45px;
  height: 40px;
  line-height: 90px;
  margin-right: 10px;
}
.nav_logo .logo-text {
  color: rgba(255,255,255,.9);
  font-size: 36px;
  font-family: YouSheBiaoTiHei;
}
div.el-dropdown {
  height: 60px;
}
.nav_item {
  position: relative;
  top: 0;
  right: 0;
  li {
    width: 200px;
    float: right;
    height: 90px;
    margin-right: 5px;
    line-height: 90px;
    .nav_menu {
      width: 210px;
      color: #ffffff;
      font-size: 22px;
    }
    .nav_menu:hover {
      // color: #1352C5;
      background: linear-gradient(180deg, #014C85 27.19%, #0D6AAA 75.44%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      // border-bottom: 2px solid #1F2A63;
    }
    .nav_submenu {
      color: #ffffff;
      font-size: 22px;
    }
    .nav_menu_icon {
      color: #ffffff;
      font-size: 0.0625rem;
    }
  }
}
</style>
