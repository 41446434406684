<template>
  <div>
    <!--  面包屑导航区域  -->
    <el-breadcrumb separator-class="el-icon-arrow-right" class="bread" style="">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>公司新闻</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  主题内容区域  -->
    <div class="news_content">
      <div class="news_content_header">
        <div class="link_header" @click="goPage('https://mp.weixin.qq.com/s/Vcqgl6YeCpzkgo-pateDzg')">
          <div class="news01_img">
            <img src="../assets/news01.png" alt="">
          </div>
          <div class="news01_title">
            <h2>《2024年国家标准立项指南》印发！第一批城市和产...</h2>
            <i class="el-icon-pie-chart"></i>
            <span class="hdate">2024-01-21 21:16</span>
            <div class="details">
              <span>了解详情</span>
              <i class="el-icon-right"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="news_content_body">
        <ul>
          <li> <div class="news0" @click="goPage('https://mp.weixin.qq.com/s/WnO4wEWBSAojmFusbFmukQ')">
            <div class="news02_img">
              <img src="../assets/news02_img.png" alt="">
            </div>
            <div class="news02_title">
              <h3>重磅！罕华成功入选中国钢铁工业协会《极致能效能力清单》！</h3>
              <i class="el-icon-pie-chart"></i>
              <span> 2024-01-13 11:00</span>
              <div class="icon">
                <i class="el-icon-d-arrow-right"></i>
              </div>
            </div>
          </div></li>
          <li><div class="news0" @click="goPage('https://mp.weixin.qq.com/s/oFPCj0-dd2-jlUOnkkCDdw')">
            <div class="news03_img">
              <img src="../assets/news03_img.jpg" alt="">
            </div>
            <div class="new03_title">
              <h3>重磅！国务院常务会议审议通过《碳排放权交易管理暂行条例（草案）》！</h3>
              <i class="el-icon-pie-chart"></i>
              <span>2024-01-08 14:08</span>
              <div class="icon">
                <i class="el-icon-d-arrow-right"></i>
              </div>
            </div>
          </div></li>
          <li><div class="news0" @click="goPage('https://mp.weixin.qq.com/s/lECqzN7a04c03zz28DLuEA')">
            <div class="news04_img">
              <img src="../assets/news04_img.png" alt="">
            </div>
            <div class="new03_title">
              <h3>钢铁行业碳计量体系建设及推动能源计量数据与碳计量数据有效衔接和综合利用交流会成功召开！</h3>
              <i class="el-icon-pie-chart"></i>
              <span>2023-12-23 14:25</span>
              <div class="icon">
                <i class="el-icon-d-arrow-right"></i>
              </div>
            </div>
          </div></li>
          <li><div class="news0" @click="goPage('https://mp.weixin.qq.com/s/PrQ8iZCe7_iKsOt1rA3M0Q')">
            <div class="news05_img">
              <img src="../assets/news05_img.jpg" alt="">
            </div>
            <div class="news05_title">
              <h3>发改委明确重点工作： 推动能耗双控逐步向碳排放双控转变！</h3>
              <i class="el-icon-pie-chart"></i>
              <span>2023-12-21 08:01</span>
              <div class="icon">
                <i class="el-icon-d-arrow-right"></i>
              </div>
            </div>
          </div></li>
          <li><div class="news0" @click="goPage('https://mp.weixin.qq.com/s/wpYmP5NRxR7b-1uGesyOFQ')">
            <div class="news06_img">
              <img src="../assets/news06_img.png" alt="">
            </div>
            <div class="new03_title">
              <h3>COP28关于跨境碳交易、CCER重启、企业碳核算......生态环境部释放重大信号！</h3>
              <i class="el-icon-pie-chart"></i>
              <span>2023-12-13 10:03</span>
              <div class="icon">
                <i class="el-icon-d-arrow-right"></i>
              </div>
            </div>
          </div></li>
        </ul>
      </div>
      <!-- <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[2, 5, 10, 15]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" class="page"></el-pagination> -->
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      pageNum: 1,
      pageSize: 5,
      total: 20
    }
  },
  methods: {
    handleSizeChange (newSize) {
      this.pageSize = newSize
    },
    handleCurrentChange (newNum) {
      this.pageNum = newNum
    },
    goPage (url) {
      window.open(url, '_blank')
    }
  }
}

</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  /* css3盒子模型 */
  box-sizing: border-box;
}

/* 去掉 li 的小圆点 */
li {
  list-style: none;
}
/* 消除图片下的空白缝隙 */
img {
  border: 0;
  vertical-align: middle;
}
a {
  text-decoration: none;
  cursor: pointer;
}
/* 让斜体不倾斜 */
em,
i {
  font-style: normal;
}

a:hover {
  color: #1352c5;
}
.bread {
  font-size: 24px;
  border-bottom: 1px solid #cccccc;
  padding: 20px 0 20px 350px;
}
.news_content {
  width: 1200px;
  height: 100%;
  margin: 80px auto;
  position: relative;
  //background-color: pink;
  .news_content_header {
    width: 100%;
    .link_header {
      display: block;
      padding-left: 30px;
      width: 100%;
      height: 200px;
      color: royalblue;
      border-bottom: 1px solid #cccccc;
      cursor: pointer;
      .news01_img {
        float: left;
        margin-right: 70px;
        width: 300px;
        height: 180px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .news01_title {
        float: left;
        h2 {
          font-size: 30px;
          margin-bottom: 20px;
        }
        .hdate {
          margin-left: 8px;
        }
        .details {
          margin-top: 61px;
          text-align: center;
          width: 200px;
          height: 40px;
          line-height: 40px;
          border: 1px solid royalblue;
          span {
            margin-right: 20px;
            font-size: 18px;
          }
          i {
            font-size: 16px;
          }
        }
        .details:hover {
          background-color: #0099ff;
          color: white;
        }
      }
    }
  }
  .news_content_body {
    width: 100%;
    ul {
      li {
        position: relative;
        width: 100%;
        height: 200px;
        border-bottom: 1px solid #cccccc;
        padding-top: 50px;
        padding-left: 30px;
        transition: all 0.3s linear;
        .news0 {
          display: block;
          width: 100%;
          height: 200px;
          cursor: pointer;
          h3 {
            color: black;
            font-weight: normal;
            margin-bottom: 30px;
            font-size: 26px;
          }
          span {
            margin-left: 8px;
            color: #aaa;
            font-weight: normal;
          }
          .icon {
            position: absolute;
            top: 90px;
            right: 10px;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            border: 1px solid #cccccc;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              font-size: 20px;
              color: #cccccc;
            }
          }
        }
      }
      li:hover {
        transform: translateY(10px);
        h3 {
          color: #0856e2;
        }
        .news0 {
          .icon {
            border: 1px solid rgba(9, 109, 217, 1);
            i {
              color: rgba(9, 109, 217, 1);
            }
          }
        }
      }
    }
  }
  .page {
    position: absolute;
    left: 50%;
    bottom: -40px;
    font-size: 24px;
    transform: translateX(-50%);
  }
}
.news02_img, .news03_img, .news04_img, .news05_img, .news06_img {
  float: left;
  width: 300px;
  height: 180px;
  border: 1px solid #cccccc;
  margin-top: -40px;
  margin-right: 70px;
  img {
    width: 100%;
    height: 100%;
  }
}
.news02_title, .news03_title, .news04_title, .news05_title, .news06_title {
  float: left;
}
</style>
