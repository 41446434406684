import { render, staticRenderFns } from "./Quality.vue?vue&type=template&id=9b28fc66&scoped=true&"
import script from "./Quality.vue?vue&type=script&lang=js&"
export * from "./Quality.vue?vue&type=script&lang=js&"
import style0 from "./Quality.vue?vue&type=style&index=0&id=9b28fc66&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b28fc66",
  null
  
)

export default component.exports