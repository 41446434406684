<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style>
body {
  margin: 0!important;
  min-width:1000px !important;
  overflow:auto !important;
}
</style>
