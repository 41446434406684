import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index'
import About from '../views/About'
import Product from '../views/Product'
import News from '../views/News'
import Join from '../views/Join'
import Product1 from '@/products/Product1'
import News0 from '@/news/News0'
import Join0 from '@/join/Join0'
import Join1 from '@/join/Join1'
import Join2 from '@/join/Join2'
import Join3 from '@/join/Join3'
import Quality from '@/views/Quality'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
      path: '/index',
      component: Index
    },
    {
      path: '/about',
      component: About
    },
    {
      path: '/about/:id',
      component: About
    },
    {
      path: '/product',
      component: Product,
      // redirect: '/product0',
      children: [
        {
          path: 'product1',
          component: Product1
        }
      ]
    },
    {
      path: '/product/:id',
      component: Product,
      // redirect: '/product0',
      children: [
        {
          path: 'product1',
          component: Product1
        }
      ]
    },
    {
      path: '/news',
      component: News,
      children: [
        {
          path: '',
          component: News0
        },
        {
          path: 'news0',
          component: News0
        }
      ]
    },
    {
      path: '/news/:id',
      component: News,
      children: [
        {
          path: '',
          component: News0
        },
        {
          path: 'news0',
          component: News0
        }
      ]
    },
    {
      path: '/join',
      component: Join,
      children: [
        {
          path: '',
          component: Join0
        },
        {
          path: 'join0',
          component: Join0
        },
        {
          path: 'join1',
          component: Join1
        },
        {
          path: 'join2',
          component: Join2
        },
        {
          path: 'join3',
          component: Join3
        }
      ]
    },
    {
      path: '/join/:id',
      component: Join,
      children: [
        {
          path: '',
          component: Join0
        },
        {
          path: 'join0',
          component: Join0
        },
        {
          path: 'join1',
          component: Join1
        },
        {
          path: 'join2',
          component: Join2
        },
        {
          path: 'join3',
          component: Join3
        }
      ]
    },
    {
      path: '/quality',
      component: Quality
    },
    {
      path: '/quality/:id',
      component: Quality
    }
  ]
})

export default router
