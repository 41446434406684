<template>
  <div class="container">
    <div id="top"></div>
    <!--  导航栏区域  -->
    <Header />
    <!--  头部区域  -->
    <div class="news_header">
      <div class="news_header_bg"></div>
      <h1 class="news_header_title">新闻中心</h1>
    </div>
    <div class="news_content">
      <router-view></router-view>
    </div>
    <!--  回到顶部组件  -->
    <!--    <template>-->
    <!--      <el-backtop target=".container" :visibility-height="20" :bottom="400">-->
    <!--        <div style="color: #000">回到顶部</div>-->
    <!--      </el-backtop>-->
    <!--    </template>-->
    <!--  底部区域  -->
    <div class="bottom">
      <dl>
        <dt>产品技术</dt>
        <!-- <dd><router-link :to="'/goods/' + goodsId">产品体系平台</router-link></dd> -->
        <dd>
          <router-link :to="'/product/product1'"
            >WCC数据在线校准系统</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >传感器设备</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >EMS能源管理系统（冶金）</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >EMS能源管理系统（化工）</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >CES碳排放管理系统</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >重要能耗设备能效管理</router-link
          >
        </dd>
      </dl>
      <dl>
        <dt>加入我们</dt>
        <dd><router-link to="/join">校园招聘</router-link></dd>
        <dd><router-link to="/join">社会招聘</router-link></dd>
      </dl>
      <dl>
        <dt>关于我们</dt>
        <dd>
          <router-link :to="'/about/' + introductionId">公司简介</router-link>
        </dd>
        <dd><router-link :to="'/about/' + cultureId">公司文化</router-link></dd>
        <dd>
          <router-link :to="'/about/' + relationId">联系我们</router-link>
        </dd>
        <dd><router-link :to="'/about/' + addressId">公司地址</router-link></dd>
      </dl>
      <dl>
        <dt>新闻中心</dt>
        <dd><router-link to="/news">公司新闻</router-link></dd>
      </dl>
      <dl class="wx">
        <dt>关注我们</dt>
        <dd>
          <img src="../assets/wx.jpg" title="南京罕华流体技术有限公司" />
        </dd>
      </dl>
      <div class="copyRight">
        <p>
          Copyright 2020 ©南京罕华流体技术有限公司 |
          <a href="http://www.beian.miit.gov.cn">苏ICP备19074445号</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/head/Header.vue'
export default {
  components: {
    Header
  },
  data () {
    return {
      goodsId: 0,
      content1Id: 1,
      content2Id: 2,
      aboutId: 0,
      joinId: 0,
      newsId: 0,
      productId: 0,
      qualityId: 0,
      indexId: 0,
      introductionId: 1,
      cultureId: 2,
      relationId: 3,
      addressId: 4
    }
  },
  mounted () {
    var id = this.$route.params.id
    if (id === '0') {
      document.querySelector('#top').scrollIntoView(true)
    }
  },
  methods: {
    toTop () {
      document.querySelector('#top').scrollIntoView(true)
    }
  }
}

</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  position: relative;
}
.news_header {
  animation: card 1s linear;
  position: relative;
  width: 100%;
  height: 600px;
  .news_header_bg {
    position: relative;
    width: 100%;
    height: 100%;
    background: url("../assets/news_bg1.jpg")  no-repeat;
    background-size: 100% 100%;
  }
  .news_header_bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    background-color: rgba(0,0,0,0.4);
  }
  .news_header_title {
    //animation: card 1s linear;
    position: absolute;
    top: 300px;
    left: 0;
    //display: none;
    width: 100%;
    color: white;
    font-size: 50px;
    font-weight: normal;
    letter-spacing: 10px;
    text-align: center;
  }
}

.news_content {
  animation: card 1s linear;
}
@keyframes card  {
  0% {
    transform: translateY(120px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
//  底部区域
.bottom {
  position: relative;
  width: 100%;
  height: 600px;
  background-color: black;
  color: white;
  padding-left: 200px;
  dl {
    float: left;
    height: 100px;
    margin: 130px 70px;
    dt {
      font-size: 28px;
      color: #fff;
      margin-bottom: 15px;
    }
    dd {
      font-size: 21px;
      margin: 8px 0;
      a {
        color: #ccc;
      }
    }
  }
  dl:first-child {
    margin-right: 30px;
  }
  .wx {
    dt {
      text-align: center;
    }
    dd img {
      width: 110px;
      height: 110px;
    }
  }
  .copyRight {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 22px;
    p {
      text-align: center;
      a {
        color: white;
      }
    }
  }
}
</style>
