<template>
<!--  软件工程师  -->
  <div style="background-color: #eeeeee">
    <!--  面包屑导航区域  -->
    <el-breadcrumb separator-class="el-icon-arrow-right" class="bread">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/join/join0' }">加入我们</el-breadcrumb-item>
      <el-breadcrumb-item>软件工程师</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="join_content">
      <h2>软件工程师</h2>
      <h4>研发岗位及描述:</h4>
      <p>
        <span>一. Java后端开发工程师（2~3人）</span>
        <br>
        <br>
        岗位要求：
        <br>
        <br>
        1、本科及以上学历、计算机相关专业、对工业平台有浓厚兴趣、设计和编写代码能力优秀
        <br>
        <br>
        2、熟悉java多线程开发，了解HTTP、TCP/IP协议，了解JVM虚拟机，了解或使用过SpringBoot和SpringCloud优先，熟悉常见设计模式，阅读过开源框架底层源码者优先
        <br>
        <br>
        3、熟练使用各种开发调试软件，有Linux部署经验和调试经验尤佳
        <br>
        <br>
        4、熟悉MySQL、Oracle的使用，优化
        <br>
        <br>
        <br>
        职位描述：
        <br>
        <br>
        1、 负责公司工业系统的开发与维护
        <br>
        <br>
        2、 参与软件产品的设计并完成核心代码
        <br>
        <br>
        3、 参与制定软件开发流程和标准并推行使用
        <br>
        <br>
        <br>
        <span>二.  WEB前端开发工程师（1~2人）</span>
        <br>
        <br>
        岗位要求：
        <br>
        <br>
        1、本科及以上学历、计算机相关专业、对工业平台设计有浓厚兴趣
        <br>
        <br>
        2、熟悉W3C标准，能熟练运用HTML、HTML5、CSS进行合理的页面排版布局，熟悉各种主流浏览器的CSS兼容性问题的解决方法
        <br>
        <br>
        3、熟悉Bootstrap、Vue、layui、React、Webpack、Gulp等主流框架技术
        <br>
        <br>
        4、熟悉less或scss， 熟悉webpack打包并了解打包原理
        <br>
        <br>
        5、熟练操作Photoshop、Sublime text、WebStorm、HBuilder等设计开发工具，能运用工具进行页面切片、交互代码编写、优化等工作
        <br>
        <br>
        6、熟练使用Git、svn等项目代码管理工具
        <br>
        <br>
        7、有良好的编码习惯，能在设计的过程中充分考虑后台的编程对接，对前后端合作模式有深入理解
        <br>
        <br>
        8、熟悉用户体验设计和交互设计
        <br>
        <br>
        9、有较强的学习能力和适应能力
        <br>
        <br>
        <br>
        职位描述：
        <br>
        <br>
        1、负责公司核心产品的前端页面开发
        <br>
        <br>
        2、协同后端开发人员定义数据接口与逻辑交互
        <br>
        <br>
        3、根据项目开发要求高质高效完成任务，并兼顾用户体验、性能、可维护性
        <br>
        <br>
        <br>
        <span>三.  运维工程师（1~2人）</span>
        <br>
        <br>
        岗位要求：
        <br>
        <br>
        1、本科及以上学历、计算机相关专业
        <br>
        <br>
        2、掌握服务器、操作系统、数据库、网络等基础知识
        <br>
        <br>
        3、精通Shell/Python/Perl等1至2种编程语言
        <br>
        <br>
        4、熟练掌握数据结构和算法，并能灵活运用
        <br>
        <br>
        5、熟悉网络基础知识，DNS、防火墙、网关、TCP/IP、HTTP，精通socket网络编程
        <br>
        <br>
        6、熟悉分布式计算或存储系统，Hadoop、Hbase、Storm等
        <br>
        <br>
        7、熟练使用开源监控平台工具，如：Nagios，zabbix等
        <br>
        <br>
        <br>
        职位描述：
        <br>
        <br>
        1、负责公司产品的测试、维护、优化
        <br>
        <br>
        2、负责公司网络的维护
        <br>
        <br>
        3、需有发现问题、跟踪问题、快速解决问题的能力
        <br>
        <br>
        4、服务器部署，网络关联、远程维护、虚拟化
        <br>
        <br>
        5、需接受出差和偶尔加班
        <br>
        <br>
        <br>
      </p>
      <p>
        招聘人数：4~7人。
        <br>
        <br>
        薪资待遇：提供具有行业竞争力的工资，具体面议
        <br>
        <br>
        福利待遇：员工宿舍、五险、开发奖、年终奖等
        <br>
        <br>
        <br>
        工作地点：南京市鼓楼区新模范马路66号南邮大厦
        <br>
        <br>
        联系电话：025-52216015
        <br>
        <!-- <br>
        联系微信：13555781039
        <br> -->
        <br>
        简历投递邮箱：hhfluid@163.com
        <br><br>
      </p>
      <p style="color: red;font-weight: bold;">
        南京罕华期待您的加入！
        <br><br>
        逐梦之旅    即将开始
      </p>
    </div>
  </div>
</template>

<script>
export default {}

</script>

<style lang="less" scoped>
@keyframes card  {
  0% {
    transform: translateY(120px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.bread {
  font-size: 22px;
  border-bottom: 1px solid #cccccc;
  padding: 20px 0 20px 350px;
}
.join_content {
  animation: card 1s linear;
  width: 1200px;
  margin: 0px auto;
  padding: 80px 0;
  background-color: white;
  h2 {
    text-align: center;
    margin-bottom: 50px;
    letter-spacing: 5px;
    font-size: 34px;
  }
  h4 {
    padding-left: 20px;
    font-size: 28px;
  }
  p {
    padding-left: 50px;
    font-size: 24px;
    span {
      font-weight: bolder;
      font-size: 26px
    }
  }
}
</style>
