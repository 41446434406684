<template>
  <div class="container">
    <div id="top"></div>
    <Header />
    <!--  头部区域  -->
    <div class="about_header">
      <h1 class="about_header_title">荣誉资质</h1>
    </div>
    <div class="sub_nav">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
        <el-menu-item index="1" @click="toAAA"><span>AAA资信</span></el-menu-item>
        <el-menu-item index="2" @click="toISO"><span>ISO体系</span></el-menu-item>
        <el-menu-item index="3" disabled><span>知识产权贯标</span></el-menu-item>
        <el-menu-item index="4" disabled><span>高新技术企业</span></el-menu-item>
        <el-menu-item index="5" disabled><span>创业人才支持</span></el-menu-item>
      </el-menu>
    </div>
    <div class="quality_content">
      <div class="return" @click="returnTop">
        <img src="../assets/icon_return.png" alt="">
      </div>
      <div id="A"></div>
      <div class="quality_content1">
        <span class="line1"></span>
        <span class="A">AAA资信认证</span>
        <ul>
          <li>
            <img src="../assets/quality_aaa1.png" alt="">
          </li>
          <li>
            <img src="../assets/quality_aaa2.png" alt="">
          </li>
        </ul>
      </div>
      <div id="ISO"></div>
      <div class="quality_content2">
        <span class="line2"></span>
        <span class="ISO">ISO体系认证</span>
        <ul>
          <li>
            <img src="../assets/quality_iso1.jpg" alt="">
          </li>
          <li><img src="../assets/quality_iso2.jpg" alt=""></li>
          <li><img src="../assets/quality_iso3.jpg" alt=""></li>
          <li><img src="../assets/quality_iso4.jpg" alt=""></li>
          <li><img src="../assets/quality_iso5.jpg" alt=""></li>
          <li><img src="../assets/quality_iso6.jpg" alt=""></li>
        </ul>
      </div>
    </div>
    <!--  底部区域  -->
    <div class="bottom">
      <dl>
        <dt>产品技术</dt>
        <!-- <dd><router-link :to="'/goods/' + goodsId">产品体系平台</router-link></dd> -->
        <dd>
          <router-link :to="'/product/product1'"
            >WCC数据在线校准系统</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >传感器设备</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >EMS能源管理系统（冶金）</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >EMS能源管理系统（化工）</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >CES碳排放管理系统</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >重要能耗设备能效管理</router-link
          >
        </dd>
      </dl>
      <dl>
        <dt>加入我们</dt>
        <dd><router-link to="/join">校园招聘</router-link></dd>
        <dd><router-link to="/join">社会招聘</router-link></dd>
      </dl>
      <dl>
        <dt>关于我们</dt>
        <dd>
          <router-link :to="'/about/' + introductionId">公司简介</router-link>
        </dd>
        <dd><router-link :to="'/about/' + cultureId">公司文化</router-link></dd>
        <dd>
          <router-link :to="'/about/' + relationId">联系我们</router-link>
        </dd>
        <dd><router-link :to="'/about/' + addressId">公司地址</router-link></dd>
      </dl>
      <dl>
        <dt>新闻中心</dt>
        <dd><router-link to="/news">公司新闻</router-link></dd>
      </dl>
      <dl class="wx">
        <dt>关注我们</dt>
        <dd>
          <img src="../assets/wx.jpg" title="南京罕华流体技术有限公司" />
        </dd>
      </dl>
      <div class="copyRight">
        <p>
          Copyright 2020 ©南京罕华流体技术有限公司 |
          <a href="http://www.beian.miit.gov.cn">苏ICP备19074445号</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Header from '../components/head/Header.vue'
export default {
  components: {
    Header
  },
  data () {
    return {
      goodsId: 0,
      content1Id: 1,
      content2Id: 2,
      aboutId: 0,
      joinId: 0,
      newsId: 0,
      productId: 0,
      qualityId: 0,
      indexId: 0,
      activeIndex: '1',
      introductionId: 1,
      cultureId: 2,
      relationId: 3,
      addressId: 4
    }
  },
  mounted () {
    $('.quality_content2').css('display', 'block')
    var id = this.$route.params.id
    if (id === '0') {
      document.querySelector('#top').scrollIntoView(true)
    } else if (id === '1') {
      document.querySelector('#A').scrollIntoView(true)
    } else if (id === '2') {
      document.querySelector('#ISO').scrollIntoView(true)
    }
    $(window).on('scroll', function () {
      if ($(window).scrollTop() >= 400) {
        $('.nav').css('background-color', 'rgba(169,169,171,0.6)')
      } else {
        $('.nav').css('background-color', 'rgba(255,255,255,.1)')
      }
      if ($(window).scrollTop() >= 800) {
        // $('.quality_content2').fadeIn(3000)
        $('.quality_content2').css('display', 'block')
        $('.return').css('display', 'block')
      } else {
        $('.return').css('display', 'none')
      }
      if ($(window).scrollTop() >= 1240) {
        // $('.quality_content2').fadeIn(3000)
        $('.bottom').css('display', 'block')
      }
    })
  },
  methods: {
    returnTop () {
      document.querySelector('#top').scrollIntoView(true)
    },
    toAAA () {
      document.querySelector('#A').scrollIntoView(true)
    },
    toISO () {
      document.querySelector('#ISO').scrollIntoView(true)
    }
  }
}

</script>

<style lang="less" scoped>
@keyframes card  {
  0% {
    transform: translateY(120px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.container {
  width: 100%;
  position: relative;
}
.about_header {
  animation: card 1s linear;
  width: 100%;
  height: 600px;
  background: url("../assets/quality_bg.jpg") no-repeat;
  background-size: 100% 100%;
  padding-top: 300px;
  .about_header_title {
    //display: none;
    width: 100%;
    color: white;
    margin: 0 auto;
    font-size: 50px;
    text-align: center;
    letter-spacing: 10px;
    font-weight: normal;
  }
}
.sub_nav {
  animation: card 1s linear;
  .el-menu-demo {
    padding-left: 400px;
    background-color: rgb(242,246,247);
    span {
      margin: 60px;
      font-size: 22px;
    }
  }
}
.quality_content {
  position: relative;
  width: 1200px;
  height: 2000px;
  margin: 50px auto;
  .return {
    display: none;
    position: fixed;
    right: 70px;
    bottom: 20px;
    width: 50px;
    z-index: 1;
    height: 50px;
    border-radius: 5px;
    line-height: 50px;
    background-color: #eeeeee;
    text-align: center;
    cursor: pointer;
    //border: 1px solid blue;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .quality_content1 {
    animation: card 1s linear;
    width: 100%;
    //border: 1px solid red;
    .line1 {
      display: inline-block;
      width: 10px;
      height: 40px;
      background-color: #ec8237;
    }
    .A {
      display: inline-block;
      color: black;
      font-size: 28px;
      margin-left: 15px;
      height: 40px;
    }
    ul li {
      display: inline-block;
      width: 500px;
      height: 600px;
      margin: 50px 50px;
      transition: all 0.3s linear;
      box-shadow: 0 0 7px 5px #cccccc;
      img {
        width: 100%;
        height: 100%;
      }
    }
    li:hover {
      transform: scale(1.1);
    }
  }
  .quality_content2 {
    animation: card 1.5s linear;
    display: none;
    width: 100%;
    .line2 {
      display: inline-block;
      width: 10px;
      height: 40px;
      background-color: lightgreen;
    }
    .ISO {
      display: inline-block;
      color: black;
      font-size: 28px;
      margin-left: 15px;
      height: 40px;
      margin-bottom: 50px;
    }
    ul li {
      float: left;
      width: 300px;
      height: 400px;
      margin: 70px 50px;
      transition: all 0.3s linear;
      img {
        width: 100%;
        height: 100%;
      }
    }
    li:hover {
      transform: scale(1.5);
    }
  }
}

//  底部区域
.bottom {
  position: relative;
  width: 100%;
  height: 600px;
  background-color: black;
  color: white;
  padding-left: 200px;
  dl {
    float: left;
    height: 100px;
    margin: 130px 70px;
    dt {
      font-size: 28px;
      color: #fff;
      margin-bottom: 15px;
    }
    dd {
      font-size: 21px;
      margin: 8px 0;
      a {
        color: #ccc;
      }
    }
  }
  dl:first-child {
    margin-right: 30px;
  }
  .wx {
    dt {
      text-align: center;
    }
    dd img {
      width: 110px;
      height: 110px;
    }
  }
  .copyRight {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 22px;
    p {
      text-align: center;
      a {
        color: white;
      }
    }
  }
}
</style>
