import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import './plugins/element.js'
import 'lib-flexible'
//  导入全局样式表
import './assets/static/css/global.css'
import './assets/static/fonts/iconfont.css'
import TreeTable from 'vue-table-with-tree-grid'
//  导入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
//  导入富文本编辑器对应样式
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
//  导入nprogress 包对应的js和css
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
import './assets/static/css/font.css'
import VueAMap from 'vue-amap'
Vue.use(VueAMap)
// 初始化vue-amap
VueAMap.initAMapApiLoader({
  key: 'da17569c624541a7eceed1098c4592a8',
  plugin: [
    'AMap.Autocomplete',
    // 输入提示插件
    'AMap.PlaceSearch',
    // POI搜索插件
    'AMap.Scale',
    // 右下角缩略图插件 比例尺
    'AMap.OverView',
    // 地图鹰眼插件
    'AMap.ToolBar',
    // 地图工具条
    'AMap.MapType',
    // 类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
    'AMap.PolyEditor',
    // 编辑 折线多，边形
    'AMap.CircleEditor',
    // 圆形编辑器插件
    'AMap.Geolocation'
    // 定位控件，用来获取和展示用户主机所在的经纬度位置
  ],
  uiVersion: '1.0'

})

Vue.use(VueQuillEditor /* { default global options } */)
Vue.config.productionTip = false
Vue.component('tree-table', TreeTable)

//  定义格式化时间的过滤器
Vue.filter('dateFormat', function (originVal) {
  const dt = new Date(originVal)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + '').padStart(2, '0')
  const d = (dt.getDay() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})

new Vue({
  router,
  render: h => h(App),
  data: {
    keywords: '南京罕华流体技术有限公司，罕华，流体，精准计量，工业能源解决方案，节能降耗，能源管控，钢铁企业优化调度，南京罕华，流量计',
    description: '南京罕华———精准计量解决方案服务商,主要从事基于精准计量的钢铁企业煤气系统能源管控、工业企业精准计量解决方案、计量产品及校准采集系统供应。',
    title: '南京罕华流体技术有限公司'
  },
  created () {
    this.seo()
  },
  methods: {
    seo () {
      document.title = this.title
      document.querySelector('meta[name="keywords"]').setAttribute('content', this.keywords)
      document.querySelector('meta[name="description"]').setAttribute('content', this.description)
    }
  }
}).$mount('#app')
