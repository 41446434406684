<template>
  <div>
    <!--  主题内容区域  -->
    <div class="join_content">
      <div class="join_content1">
        <h1>招聘岗位</h1>
        <div class="line"></div>
        <ul>
          <li>
            <router-link to="/join/join1">
              <div class="join_body">
                <img src="../assets/icon_software.png" alt="">
                <div class="message">
                  <h3>软件工程师</h3>
                  <span>查看详情</span>
                  <div class="icons">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/join/join2">
              <div class="join_body">
                <img src="../assets/icon_research.png" alt="">
                <div class="message">
                  <h3>研发工程师</h3>
                  <span>查看详情</span>
                  <div class="icons">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/join/join3">
              <div class="join_body">
                <img src="../assets/icon_saler.png" alt="">
                <div class="message">
                  <h3>销售工程师</h3>
                  <span>查看详情</span>
                  <div class="icons">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="join_content2"></div>
    </div>
  </div>
</template>

<script>
export default {}

</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  /* css3盒子模型 */
  box-sizing: border-box;
}

/* 去掉 li 的小圆点 */
li {
  list-style: none;
}
/* 消除图片下的空白缝隙 */
img {
  border: 0;
  vertical-align: middle;
}
a {
  text-decoration: none;
  cursor: pointer;
}
/* 让斜体不倾斜 */
em,
i {
  font-style: normal;
}

a:hover {
  color: #1352c5;
}
.join_content {
  width: 100%;
  animation: card 1s linear;
  .join_content1 {
    width: 1400px;
    margin: 0 auto;
    padding-top: 60px;
    h1 {
      text-align: center;
      font-weight: normal;
      font-size: 30px;
      letter-spacing: 5px;
    }
    .line {
      width: 60px;
      border: 1px solid #cccccc;
      margin: 20px auto;
    }
    ul {
      width: 100%;
      height: 200px;
      padding-left: 85px;
      margin-bottom: 200px;
      li {
        position: relative;
        width: 350px;
        height: 100%;
        float: left;
        margin: 20px 30px;
        border-radius: 30px;
        background-color: #6586e8;
        transition: all 0.3s linear;
        .join_body {
          position: absolute;
          left: 0;
          top: 0;
          width: 350px;
          height: 200px;
          padding-top: 55px;
          padding-left: 50px;
        }
        img {
          float: left;
          width: 80px;
          height: 80px;
          transition: all 1.5s linear;
        }
        .message {
          position: relative;
          margin-left: 30px;
          padding-top: 5px;
          color: white;
          float: left;
          h3 {
            letter-spacing: 3px;
            margin-bottom: 15px;
            font-size: 26px;
          }
          span {
            color: #dddddd;
            font-size: 18px;
          }
          .icons {
            position: absolute;
            top: 58px;
            right: 0;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            border: 1px solid #dddddd;
            text-align: center;
            line-height: 30px;
            .el-icon-arrow-right {
              font-size: 0.05rem;
            }
          }
        }
      }
      li:hover {
        background-color: #ec8237;
        img {
          transform: rotateY(360deg);
        }
      }
    }
  }
}

@keyframes card  {
  0% {
    transform: translateY(120px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
</style>
