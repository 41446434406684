<template>
<!--  研发工程师  -->
  <div style="background-color: #eeeeee">
    <!--  面包屑导航区域  -->
    <el-breadcrumb separator-class="el-icon-arrow-right" class="bread">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/join/join0' }">加入我们</el-breadcrumb-item>
      <el-breadcrumb-item>研发工程师</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="join_content">
      <h2>研发工程师</h2>
      <h4>研发岗位及描述:</h4>
      <p>
        <span>一. 冶金工艺研发</span>
        <br>
        <br>
        职位描述：
        <br>
        <br>
        1、钢铁联合企业各工序设备及工艺模型研发
        <br>
        <br>
        2、能接受加班和偶尔出差
        <br>
        <br>
        <br>
        <span>二. 冶金能源优化管控研发</span>
        <br>
        <br>
        职位描述：
        <br>
        <br>
        1、大数据分析、自学习研发
        <br>
        <br>
        2、工艺运行规则优化研发
        <br>
        <br>
        3、能接受加班和偶尔出差
        <br>
        <br>
        <br>
        <span>三.工业流体技术研发</span>
        <br>
        <br>
        职位描述：
        <br>
        <br>
        1、国内外行业前沿跟踪及信息梳理
        <br>
        <br>
        2、工业系统流体相关技术研发及解决方案
        <br>
        <br>
        3、基于流体技术的相关硬件设备研发
        <br>
        <br>
        4、能接受加班和偶尔出差
        <br><br>
      </p>
      <h4>岗位要求:</h4>
      <p>
        硕士及以上相关专业毕业
        <br>
        <br>
        招聘人数：8人
        <br>
        <br>
        <br>
        工作地点：南京市鼓楼区新模范马路66号南邮大厦
        <br>
        <br>
        简历投递：hhfluid@163.com
        <br>
        <br>
        联系电话：025-52216015
        <br><br>
      </p>
      <p style="color: red;font-weight: bold;">
        南京罕华期待您的加入！
        <br><br>
        逐梦之旅    即将开始
      </p>
    </div>
  </div>
</template>

<script>
export default {}

</script>

<style lang="less" scoped>
@keyframes card  {
  0% {
    transform: translateY(120px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.bread {
  font-size: 22px;
  border-bottom: 1px solid #cccccc;
  padding: 20px 0 20px 350px;
}
.join_content {
  animation: card 1s linear;
  width: 1200px;
  margin: 0px auto;
  padding: 80px 0;
  background-color: white;
  h2 {
    text-align: center;
    margin-bottom: 50px;
    letter-spacing: 5px;
    font-size: 34px;
  }
  h4 {
    padding-left: 20px;
    font-size: 28px;
  }
  p {
    padding-left: 50px;
    font-size: 24px;
    span {
      font-weight: bolder;
      font-size: 26px;
    }
  }
}
</style>
