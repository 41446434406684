<template>
  <div class='product'>
   <ul>
    <li :class="{activeli:activeTab===1}" @click="changeTab(1)">罕华系统产品</li>
    <li :class="{activeli:activeTab===2}" @click="changeTab(2)">罕华流量计产品</li>
    <li :class="{activeli:activeTab===3}" @click="changeTab(3)">罕华解决方案服务</li>
   </ul>
   <div class="tab_main" v-if="activeTab===1">
    <img src="../assets/system/system1.png" alt="">
    <img src="../assets/system/system2.png" alt="">
    <img src="../assets/system/system3.png" alt="">
    <img src="../assets/system/system4.png" alt="">
   </div>
   <div class="tab_main" v-if="activeTab===2">
    <img src="../assets/system/system6.png" alt="">
   </div>
   <div class="tab_main" v-if="activeTab===3">
    <img src="../assets/system/system7.png" alt="">
    <img src="../assets/system/system8.png" alt="">
   </div>
 </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {
      activeTab: 1
    }
  },
  components: {

  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    changeTab (tab) {
      if (tab === this.activeTab) return
      this.activeTab = tab
    }
  }
}
</script>

<style scoped lang='less'>
.product{
  width: 100%;
  // height: 500px;
  overflow: auto;
  background: #EEF5FC;
  padding: 20px 40px;
  box-sizing: border-box;
  ul{
    display: flex;
    li{
      height: 50px;
      width: 200px;
      background: #FFF;
      line-height: 50px;
      text-align: center;
      color: rgba(143, 144, 173, 1);
      font-size: 20px;
      cursor: pointer;
      &:nth-of-type(2){
        border-left: 1px solid #C9CDD4;
        border-right: 1px solid #C9CDD4;
      }
      &:nth-of-type(1){
       border-radius: 4px 0 0 4px;
      }
      &:nth-of-type(3){
       border-radius: 0 4px 4px 0;
      }
      &:hover{
        background: #D7EAF5;
      }
    }
    .activeli{
      background: linear-gradient(123deg, #82A0D9 10.98%, #2C69C1 33.54%, #0B55BC 88.76%) !important;
      box-shadow: 0px 2px 6px 0px rgba(0, 82, 199, 0.20);
      color: #FFF !important;
    }
  }
  .tab_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
      margin: 40px;
    }
  }
}
</style>
