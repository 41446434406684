<template>
  <div class="container">
    <div id="top"></div>
    <div class="nav">
      <!--  logo区域    -->
      <Header />
    </div>
    <!--  头部区域  -->
    <div class="about_header">
      <h1 class="about_header_title">产品解决方案</h1>
    </div>
    <div class="product_content">
      <router-view></router-view>
    </div>
    <!--  底部区域  -->
    <div class="bottom">
      <dl>
        <dt>产品技术</dt>
        <!-- <dd><router-link :to="'/goods/' + goodsId">产品体系平台</router-link></dd> -->
        <dd>
          <router-link :to="'/product/product1'"
            >WCC数据在线校准系统</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >传感器设备</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >EMS能源管理系统（冶金）</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >EMS能源管理系统（化工）</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >CES碳排放管理系统</router-link
          >
        </dd>
        <dd>
          <router-link :to="'/product/product1'"
            >重要能耗设备能效管理</router-link
          >
        </dd>
      </dl>
      <dl>
        <dt>加入我们</dt>
        <dd><router-link to="/join">校园招聘</router-link></dd>
        <dd><router-link to="/join">社会招聘</router-link></dd>
      </dl>
      <dl>
        <dt>关于我们</dt>
        <dd>
          <router-link :to="'/about/' + introductionId">公司简介</router-link>
        </dd>
        <dd><router-link :to="'/about/' + cultureId">公司文化</router-link></dd>
        <dd>
          <router-link :to="'/about/' + relationId">联系我们</router-link>
        </dd>
        <dd><router-link :to="'/about/' + addressId">公司地址</router-link></dd>
      </dl>
      <dl>
        <dt>新闻中心</dt>
        <dd><router-link to="/news">公司新闻</router-link></dd>
      </dl>
      <dl class="wx">
        <dt>关注我们</dt>
        <dd>
          <img src="../assets/wx.jpg" title="南京罕华流体技术有限公司" />
        </dd>
      </dl>
      <div class="copyRight">
        <p>
          Copyright 2020 ©南京罕华流体技术有限公司 |
          <a href="http://www.beian.miit.gov.cn">苏ICP备19074445号</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import Header from '../components/head/Header.vue'
export default {
  components: {
    Header
  },
  data () {
    return {
      goodsId: 0,
      content1Id: 1,
      content2Id: 2,
      aboutId: 0,
      joinId: 0,
      newsId: 0,
      productId: 0,
      qualityId: 0,
      indexId: 0,
      introductionId: 1,
      cultureId: 2,
      relationId: 3,
      addressId: 4
    }
  },
  mounted () {
    var id = this.$route.params.id
    if (id === '0') {
      document.querySelector('#top').scrollIntoView(true)
    }
    // $('.nav').fadeIn(800)
    $(window).on('scroll', function () {
      if ($(window).scrollTop() >= 460) {
        $('.nav').css('background-color', 'rgba(169,169,171,0.3)')
      } else {
        $('.nav').css('background-color', 'rgba(255,255,255,.1)')
      }
      if ($(window).scrollTop() >= 90) {
        $('.bottom').fadeIn(3000)
      }
    })
  },
  methods: {
    toTop () {
      document.querySelector('#top').scrollIntoView(true)
    }
  }
}

</script>

<style lang="less" scoped>
.container {
  width: 100%;
  position: relative;
}

.about_header {
  letter-spacing: 10px;
  animation: card 1s linear;
  width: 100%;
  height: 600px;
  background: url("../assets/product_bg.jpg") no-repeat;
  background-size: 100% 100%;
  padding-top: 300px;
  .about_header_title {
    //display: none;
    width: 100%;
    color: white;
    margin: 0 auto;
    font-size: 50px;
    text-align: center;
  }
}
.product_content {
  animation: card 1s linear;
}
// @keyframes card  {
//   0% {
//     transform: translateY(120px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateY(0px);
//     opacity: 1;
//   }
// }

//  底部区域
.bottom {
  position: relative;
  width: 100%;
  height: 600px;
  background-color: black;
  color: white;
  padding-left: 200px;
  dl {
    float: left;
    height: 100px;
    margin: 130px 70px;
    dt {
      font-size: 28px;
      color: #fff;
      margin-bottom: 15px;
    }
    dd {
      font-size: 21px;
      margin: 8px 0;
      a {
        color: #ccc;
      }
    }
  }
  dl:first-child {
    margin-right: 30px;
  }
  .wx {
    dt {
      text-align: center;
    }
    dd img {
      width: 110px;
      height: 110px;
    }
  }
  .copyRight {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 22px;
    p {
      text-align: center;
      a {
        color: white;
      }
    }
  }
}
</style>
