<template>
<!--  销售工程师  -->
  <div style="background-color: #eeeeee">
    <!--  面包屑导航区域  -->
    <el-breadcrumb separator-class="el-icon-arrow-right" class="bread">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/join/join0' }">加入我们</el-breadcrumb-item>
      <el-breadcrumb-item>销售工程师</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="join_content">
      <h2>销售工程师</h2>
      <h4>岗位要求:</h4>
      <p>
        <br>
        1、工科背景优先（非工科要对销售十分感兴趣）
        <br>
        <br>
        2、与公司目标保持一致，服从公司销售规划
        <br>
        <br>
        3、能接受出差和加班
        <br>
        <br>
        4、学习能力强
        <br>
        <br>
        <br>
      </p>
      <h4>职位描述:</h4>
      <p>
        <br>
        1、负责跟踪目标客户，通过努力拿下订单
        <br>
        <br>
        2、根据公司规划配合公司开拓新客户
        <br>
        <br>
        3、维护已有客户关系，建立更深层客户关系
        <br>
        <br>
        4、快速响应客户真实需求
        <br>
        <br>
        <br>
      </p>
      <h4>福利待遇:</h4>
      <p>
        <br>
        1、入职提供专业知识培训，为销售工作奠定知识储备
        <br>
        <br>
        2、培养人计划，销售主管一对一培养，包括销售技巧、实地拜访、介绍客户等
        <br>
        <br>
        3、提供住宿，步行时间只有5-10分钟，无通勤压力
        <br>
        <br>
        4、提供差旅补贴、入职车票报销等
        <br>
        <br>
        5、工资与销售业绩挂钩
        <br>
        <br>
        <br>
        招聘人数：8~10人
        <br>
        <br>
        工作地点：南京市鼓楼区新模范马路66号南邮大厦
        <br>
        <br>
        简历投递：hhfluid@163.com
        <br>
        <br>
        联系电话：025-52216015
        <br>
        <br>
      </p>
      <p style="color: red;font-size: 18px;font-weight: bold;">
        南京罕华期待您的加入！
        <br><br>
        逐梦之旅    即将开始
      </p>
    </div>
  </div>
</template>

<script>
export default {}

</script>

<style lang="less" scoped>
@keyframes card  {
  0% {
    transform: translateY(120px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.bread {
  font-size: 22px;
  border-bottom: 1px solid #cccccc;
  padding: 20px 0 20px 350px;
}
.join_content {
  animation: card 1s linear;
  width: 1200px;
  margin: 0px auto;
  padding: 80px 0;
  background-color: white;
  h2 {
    text-align: center;
    margin-bottom: 50px;
    letter-spacing: 5px;
    font-size: 34px;
  }
  h4 {
    padding-left: 20px;
    font-size: 28px;
  }
  p {
    padding-left: 50px;
    font-size: 24px;
  }
}
</style>
